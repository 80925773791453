@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}


//Basic
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

body {
  background-color: $gray-100;
  background-image: url("../../assets/images/bg.png");
  background-size: 100% 1900px;
  background-repeat: no-repeat;

}

body:after {}

.red {
  color: $red;
}

.white,
.white a,
a.white {
  color: white !important;
}

.jumbotron {

  background-color: transparent;


}

.bg-light {

  background-color: transparent !important;

}

.drop-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.text-shadow {
  text-shadow: 1px 1px 1px #000;
}

.visible-xs {
  display: none;
}

h4 {
  font-weight: bold;
font-size: 24px;
}
.list-group-item {
 
  background-color: transparent;
}
.jumbotron {
  padding: 3rem;
}
.list-group-flush  {
  font-size: 16px;
  font-weight: bold;
}
//Navbar
.navbar {
  margin-top: 40px;
  font-weight: normal;
  font-size: 18px !important;

  a {
    color: black !important;
  }

  padding-left: 0;
  padding-right: 0;

  .active {


    text-decoration: underline;


  }

}

.navbar-expand-lg .navbar-nav .nav-link {

  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;

}

.navbar.bg-light {

  background-color: transparent;

  .dropdown-menu {

    background-color:

      #F7F7F7;

    a {
      font-size: 15px;
      text-align: right;
    }

  }
}

h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: normal;
}

h3 {
  font-weight: normal;
  font-size: 20px;
}

#logo {
  margin-top: 50px;
  border-top: 1px solid $gray-300;

}

.btn-lg,
.btn-group-lg>.btn {

  padding: 0.7rem 1.2rem;

  font-size: 18px;

}
.terms p strong {
  text-transform: uppercase;
}

#intro {
  h1 {

    font-weight: 500;
    font-size: 40px;
    margin-bottom: 100px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .jumbotron {


    margin-bottom: 0;


  }

}

#slider {
  margin-bottom: 100px;
}

#about-us {
  h3 {
    font-size: 18px;
    font-weight: bold;
  }

  .personaggio {
    font-size: 13px;
    a {
      cursor: pointer!important;
    }
  }

  .immagine {
    position: relative;

    .nome {
      position: absolute;
      bottom: 30px;
      left: 30px;
      font-size: 16px;
      color: white;
      text-shadow: black 1px 1px 1px;

    }

  }

}
.carousel-caption {
  text-shadow: black 1px 1px 1px;

}
#links {
  padding-top: 50px;

  .link {
    font-weight: bold;
    font-size: 20px;
    line-height: 96px;
  }
}

#news {
  .grid {
    margin-top: 50px;
  }

  .card {
    background-color: white;
    border: none;
  }

  h5 {
    font-weight: bold;
    font-size: 22px;
  }

  h6 {
    font-weight: 500;
    font-size: 13px;
    color: #859FE1;
  }

  .card-link {
    font-weight: bold;
    font-size: 14px;
  }

  .card-body {

    padding: 20px;

    .card-image {
      .img-fluid {
        margin-bottom: 30px;
      }

    }

  }

  .card-text {
    font-weight: normal;
    font-size: 16px;
  }

}

footer {
  margin-top: 70px;
  margin-bottom: 100px;

  .logo {
    width: 53px;
  }

  span {
    font-weight: bold;
    font-size: 12px;
  }

  small {
    font-size: 12px;
  }
}

#content-post {
  .post-back {
    margin: 70px 0 70px 0;
  }

  .post-text {
    padding-right: 60px;
  }

  h1 {
    font-weight: bold;
    font-size: 40px;
  }

  .post-date {
    font-size: 16px;
  }

  .post-content {
    font-size: 20px;
  }
}

#content-about {
  margin-top: 70px;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.7;

  .immagine {
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 500px;
    margin-bottom: 70px;

  }

  h1 {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 30px;

  }

  h2 {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.2;
  }

  h3,
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.7;
  }

  .immagine {
    margin: 70px 0 30px 0;

  }
}

#content-practices {
  margin-top: 70px;
  font-weight: normal;
  font-size: 24px;

  .immagine {
    position: relative;
    //background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 600px;
    margin-bottom: 70px;

  }

  h1 {
    font-weight: 600;
    font-size: 40px;
    padding-top: 480px;
    color: white;
  }

  .practices-text {

    p,
    ul,
    li {
      font-weight: normal;
      font-size: 18px;
    }
  }

}
.grid-item {
  
}
.grid-item.selected .card{
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);

}

#content-blog, #content-page {
  margin-top: 70px;


  h1 {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 23px;
    line-height: 1.5;

  }

  h2 {
    font-weight: normal;
    font-size: 33px;
    line-height: 1.5;
  }
}

#content-page {
  margin-top: 70px;
}
.navbar-light .navbar-toggler {
color: black;
border-color: rgba(0, 0, 0, 0);

}
@media (max-width: 1199px) {}

@media (min-width: 993px) {
  .hidden-lg {
    display: none;

  }
}

@media (max-width: 992px) {
  .hidden-md {
    display: none;
  }

  #content-about .full {
    .immagine {
      
      background-attachment: unset;
    }
  }

}

@media (max-width: 768px) {
  .navbar {

    margin-top: 70px;

    font-size: 16px !important;


  }

  #logo {
    margin-top: 30px;

    img {
      width: 66px;
    }
  }


  .hidden-sm {
    display: none !important;
  }
  h2 {

    font-size: 30px;
    line-height: 1.5;

}
h3 {

  font-size: 16px;


}
  #content-blog,
  #content-practices,
  #content-post,
  #content-about {
    margin-top: 50px;

    h1 {
      font-size: 30px;
      margin-bottom: 10px;

    }

    h2 {
      font-size: 16px;
      line-height: 1.5;
    }

    .immagine {
      height: 260px;
      margin-bottom: 30px;
    }
  }
  #content-about {

    h3,
    p {
      font-size: 16px;
    }

    .immagine {
      margin: 50px 0 30px 0;
    }
  }

 
  #news {
 
    .grid {
      margin-top: 50px;
    }

    h5 {
      font-size: 18px;
    }

    h6 {
      font-size: 13px;
    }

    .card-link {
      font-size: 16px;
    }

    .card-body {
      padding: 20px;

      .card-image {
        .img-fluid {
          margin-bottom: 30px;
        }
      }
    }

    .card-text {
      font-size: 14px;
    }
  }

  #intro {

    h3 {

      font-size: 16px;
      margin-bottom: 30px;
    }

    h1 {

      font-size: 30px;
      line-height: 37px;
    }
  }
  #about-us .immagine .nome {
    bottom: 20px;
    left: 20px;
    font-size: 12px;

}
#about-us .personaggio {

  font-size: 14px;
  margin-bottom: 30px;


}

#links .link {

  font-size: 18px;
  line-height: 68px;

}
#content-post .post-back {

  margin: 30px 0 30px 0;

}
#content-post .post-date {

  font-size: 13px;
  margin: 10px 0 10px 0;

}
#content-post .post-content {

  font-size: 16px;

}
#content-post .post-text {

  padding-right: 10px;

}
#content-practices .practices-text {

  font-size: 16px;

p, ul, li {
  font-size: 16px;
}

}
#content-practices h1 {

  font-size: 30px;
  padding-top: 180px;


}
.navbar-expand-lg .navbar-nav .nav-link {

  display: inline-block;

  font-size: 1.17188rem;
  white-space: nowrap;
  padding-right: 0 !important;

  padding-left: 0 !important;
}
.navbar.bg-light .dropdown-menu a {

  font-size: 1.17188rem;
  text-align: left;

}
.carousel-caption {

  bottom: 0;


}
}
  @media (max-width: 576px) {
    .hidden-xs {
      display: none;
    }

    .visible-xs {
      display: inline-block;
    }
  }